import React from 'react';
import HomePage from './HomePage';
import NewsList from './NewsList';
import logo from "../../images/logoURPS.jpg";

const Index = ({isLoading, isAuthenticated}) => {

    if (isLoading) {
        return (
            <div className="blocks-box">
                <img src={logo} alt="logo"/>
            </div>
        )
    } else {
        if (isAuthenticated === true) {
            return <NewsList/>
        } else {
            return <HomePage/>
        }
    }
};

export default Index
