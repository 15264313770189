import React, {useContext, useEffect, useState} from 'react';
import {HashRouter, Route, Switch, withRouter} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bulma/css/bulma.css'
import AuthAPI from "./services/authAPI";
import AuthContext from './contexts/AuthContext';

import NavBar from "./components/NavBar"
import Footer from "./components/footer/Footer"
import News from './pages/home/NewsList';
import IndexHome from './pages/home/Index';
import Periods from "./pages/periods/Periods";
import LoginPage from "./pages/LoginPage";
import Legals from "./pages/Legals";
import Profile from "./pages/profile/Profile";
import RegisterPage from "./pages/RegisterPage";
import NotFound from './pages/NotFound';
import Faqs from './pages/faq/FaqList';
import Acts from './pages/acts/Index';
import Act from './pages/acts/Act';
import Consultation from './pages/consultation/Index';
import {UserRoute} from "./components/ProtectedRoutes";
import MotifContext from "./contexts/MotifContext";

const NavBarWithRouter = withRouter(NavBar);

const App = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(undefined);
    const [user, setUser] = useState(AuthAPI.getUserFromStorage());

    const fetchAuthStatus = async () => {
        try {
            const isAuth = await AuthAPI.setupAxios();
            setIsAuthenticated(isAuth);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const [motifListing, setMotifListing] = useState(1);
    const [motifPricing, setMotifPricing] = useState(1);

    useEffect(() => {
        fetchAuthStatus();
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            user,
            setUser
        }}>
            <MotifContext.Provider value={{motifListing,setMotifListing, motifPricing, setMotifPricing}}>

            <HashRouter>
                <section className="hero is-fullheight">
                    <NavBarWithRouter/>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />

                    <Switch>
                        <Route exact path="/"
                               render={(props) => <IndexHome {...props} isLoading={isLoading} isAuthenticated={isAuthenticated}/>}
                        />

                        <Route exact path="/periods" component={Periods}/>
                        <Route exact path="/login" component={LoginPage}/>
                        <Route exact path="/register" component={RegisterPage}/>
                        <Route exact path="/legals" component={Legals}/>

                        <UserRoute exact path="/news" component={News}/>
                        <UserRoute exact path="/faqs" component={Faqs}/>
                        <UserRoute exact path="/actes" component={Acts}/>
                        <UserRoute exact path="/acte/:id" component={Act}/>

                        <UserRoute exact path="/consultation" component={Consultation}/>

                        <UserRoute exact path="/profil" component={Profile}/>

                        <Route component={NotFound}/>
                    </Switch>

                    <Footer/>
                </section>
            </HashRouter>

            </MotifContext.Provider>
        </AuthContext.Provider>
    );
};

export default App;
