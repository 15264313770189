import React, {useContext, useEffect, useState} from 'react';
import UserApi from "../../services/userApi";
import AuthAPI from "../../services/authAPI";
import AuthContext from "../../contexts/AuthContext";
import "./style.scss";
import {toast} from 'react-toastify';
import axios from "axios";
import Field from "../../components/form/Field";

const Profile = ({history}) => {
    const {setIsAuthenticated, setUser: setUserStorage, user: userStorage} = useContext(AuthContext);
    const userId = userStorage.id;
    const [isLoading, setIsLoading] = useState(true);

    const toggleModal = () => {
        const modalPassword = document.getElementById('password-modal');
        modalPassword.classList.toggle('is-active');
    };

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        rpps: '',
        metropole: true
    });

    const [passwordInfo, setPasswordInfo] = useState({
        password: '',
        passwordConfirm: '',
    });

    const [errors, setErrors] = useState({
        password: '',
        passwordConfirm: '',
    });

    const handleChange = ({currentTarget}) => {
        const value = currentTarget.value;
        const name = currentTarget.name;
        setPasswordInfo({
            ...passwordInfo,
            [name]: value
        });
        setErrors('');
    };

    // Permet de récupérer les infos du profile
    const fetchProfile = async () => {
        try {
            const {data} = await UserApi.findCurrentUser(userId);
            setUser(data);
            setIsLoading(false);
        } catch ({response}) {
            if (response.status === 401) {
                AuthAPI.refreshToken()
                    .then(() => fetchProfile())
                    .catch(() => {
                        AuthAPI.logout();
                        setIsAuthenticated(false)
                    });
            }
            console.log(response);
            setIsLoading(false);
        }
    };

    /**
     * delete one customer from API
     * @returns {Promise<void>}
     */
    const handleDelete = async () => {
        if (window.confirm('Voulez-vous vraiment supprimer votre compte ?')) {
            try {
                await axios.delete(process.env.REACT_APP_API_ENTRYPOINT + '/users/' + userId);
                AuthAPI.logout();
                setIsAuthenticated(false);
                setUserStorage({});
                history.push('/');
                toast.success(`Votre compte a bien été supprimé`);
            } catch ({response}) {
                toast.error(`Le compte n'a pas pu être supprimé`);
            }
        }
    };

    const handleChangePassword = async event => {
        event.preventDefault();
        const apiErrors = {};
        if (passwordInfo.password === passwordInfo.passwordConfirm) {
            try {
                await axios.put(process.env.REACT_APP_API_ENTRYPOINT + "/users/" + userId,
                    {...user, password: passwordInfo.password});
                setErrors({});
                toggleModal();
                toast.success(" Mot de passe modifié");
                history.push("/profil");
            } catch ({response}) {
                const {violations} = response.data;
                if (violations) {
                    violations.forEach(({propertyPath, message}) => {
                        apiErrors[propertyPath] = message;
                    });
                    toast.error("  Une erreur est survenue ");
                    setErrors(apiErrors);
                }
                if (passwordInfo.passwordConfirm === "") {
                    setErrors({...apiErrors, passwordConfirm: "la confirmation est requise"});
                }
            }
        } else {
            setErrors({
                ...apiErrors,
                password: "Vos mot de passe sont différents",
                passwordConfirm: "Vos mot de passe sont différents"
            });
        }
    };

    const renderProfileInfo = () => {
        if (isLoading) {
            return (
                <div className="spinner-block">
                    <p className="has-text-centered">
                        <i class="fas fa-spinner fa-spin fa-3x"/>
                    </p>
                </div>
            )
        } else {
            return (
                <div className="box">
                    <div>
                        <nav className="level">
                            <div className="level-item has-text-centered">
                                <div>
                                    <p className="heading">Email</p>
                                    <p className="title is-4">{user.email}</p>
                                </div>
                            </div>
                            <div className="level-item has-text-centered">
                                <div>
                                    <p className="heading">Numéro RPPS</p>
                                    <p className="title is-4">{user.rpps}</p>
                                </div>
                            </div>
                            <div className="level-item has-text-centered">
                                <div>
                                    <p className="heading">Adresse</p>
                                    <p className="subtitle is-4">{user.address}</p>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            )
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    return (
        <div className="content-body">
            <div className="container">
                <h1 className="title is-2">Mon Compte</h1>

                <h2 className="subtitle is-4">Bienvenue sur votre profile &nbsp;
                    <strong>{userStorage.firstName} {userStorage.lastName}</strong></h2>

                <div className="tag-box">
                    <span className="tag is-rounded is-medium is-info">{user.metropole ? "Metropole" : "DOMTOM"}</span>
                </div>

                {renderProfileInfo()}

                <div className="is-flex-between">
                    <button
                        onClick={toggleModal}
                        className="button is-primary is-outlined is-rounded">
                        <i className="fas fa-trash"/>&nbsp; Modifier mon mot de passe
                    </button>

                    <button
                        onClick={handleDelete}
                        className="button is-danger is-outlined is-rounded">
                        <i className="fas fa-trash"/>&nbsp; Supprimer mon compte
                    </button>
                </div>

                <div className="modal" id="password-modal">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Changement de mot de passe</p>
                            <button className="delete" aria-label="close" onClick={toggleModal}></button>
                        </header>
                        <section className="modal-card-body">
                            <form>
                                <Field label="Nouveau mot de passe : "
                                       name="password"
                                       type="password"
                                       required={true}
                                       icon="lock"
                                       value={user.password}
                                       error={errors.password}
                                       onChange={handleChange}
                                />
                                <Field label="Confirmer votre mot de passe : "
                                       name="passwordConfirm"
                                       type="password"
                                       required={true}
                                       icon="lock"
                                       value={user.passwordConfirm}
                                       error={errors.passwordConfirm}
                                       onChange={handleChange}
                                />
                            </form>
                        </section>
                        <footer className="modal-card-foot">
                            <button className="button is-success" onClick={handleChangePassword}
                                    type="submit">
                                Enregister
                            </button>
                            <button className="button" onClick={toggleModal}>Annuler</button>
                        </footer>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Profile;
