import React, {useContext} from 'react';
import MotifApi from "../../services/motifApi";
import NoResult from "../../components/NoResult";
import AuthContext from "../../contexts/AuthContext";

const List = ({history, handleChange, motif, setMotif, setType, search, setSearch, filteredActs, handleActClick, type, loading}) => {


    const {user} = useContext(AuthContext);

    const renderClassWithType = (type) => {
        switch (type) {
            case "CCAM":
                return "is-info";
            case "NGAP":
                return "is-link";
            case "aucun":
                return "is-light";
            case "IVG":
                return "is-primary";
            default:
                return "is-light";
        }
    };

    const selectAct = act => {
        if (type === "acts") {
            history.push('/acte/' + act.id)
        } else if (type === "consultation") {
            handleActClick(act.id)
        }
    };

    const renderActs = () => {
        if (loading) {
            return (
                <div className="spinner-block">
                    <p className="has-text-centered">Chargement</p>
                    <progress className="progress is-small is-primary" max="100">15%</progress>
                </div>
            )
        }
        return (filteredActs.length > 0 &&
            <div className="table-mobile">
                <table className="table is-striped is-hoverable is-narrow-mobile is-full-desktop" style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th className="is-hidden-mobile">Code</th>
                        <th>Type</th>
                        <th className="is-hidden-mobile text-center">Prix</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredActs.map(act => <tr key={act.id} className="is-pointer">
                        <td>{act.name}</td>
                        <td className="is-hidden-mobile" style={{whiteSpace: "nowrap"}}>{act.code} {act.factor}</td>
                        <td>
                            <span className={"tag " + renderClassWithType(act.type)}>
                                {act.type}
                            </span>
                        </td>
                        <td className="is-hidden-mobile text-center">
                            {user.metropole ? act.metropolePrice : act.domtomPrice} €
                        </td>
                        <td>
                            <button className="button is-outlined is-primary" onClick={() => selectAct(act)}>
                                {type === "acts" ? "En voir plus" : "Ajouter"}
                            </button>
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>)
        || (search.length > 0 &&
            <NoResult
                name="acte"
            />)
    }

    return (
        <div>
            <div className="choice-block">
                <h2 className="subtitle is-4">Motif de l'acte </h2>
                <div className="select is-info">
                    <select
                        name="motif"
                        value={motif}
                        onChange={(event) => handleChange(setMotif, event)}
                        className="form-control"
                    >
                        {MotifApi.ALL_MOTIFS.map((motif, key) =>
                            <option value={key + 1} key={key}>
                                {motif.value}
                            </option>
                        )}
                    </select>
                </div>
            </div>

            <div className="has-text-centered type-block">
                <label className="has-text-weight-medium"> Type d'acte : </label>
                <label className="radio">
                    <input type="radio" name="type" value="CCAM NGAP IVG aucun"
                           onClick={(event) => handleChange(setType, event)}/>
                    &nbsp;Tous
                </label>
                <label className="radio">
                    <input type="radio" name="type" value="CCAM" onClick={(event) => handleChange(setType, event)}/>
                    &nbsp;CCAM
                </label>
                <label className="radio">
                    <input type="radio" name="type" value="NGAP" onClick={(event) => handleChange(setType, event)}/>
                    &nbsp;NGAP
                </label>
            </div>

            <div className="field">
                <div className="control">
                    <input
                        className="input is-primary is-rounded"
                        type="text"
                        placeholder="Rechercher ..."
                        value={search}
                        onChange={(event) => handleChange(setSearch, event)}
                    />
                </div>
            </div>

            {renderActs()}
        </div>
    );
};

export default List;
