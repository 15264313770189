import React, {useContext, useState} from "react";
import Field from "../components/form/Field";
import AuthContext from '../contexts/AuthContext';
import AuthAPI from '../services/authAPI';

const LoginPage = ({history}) => {
    const {setIsAuthenticated, setUser: setUserLocal} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        username: '',
        password: '',
    });

    const [error, setError] = useState('');

    const handleChange = ({currentTarget}) => {
        const value = currentTarget.value;
        const name = currentTarget.name;
        setUser({
            ...user,
            [name]: value
        });
        setError('');
    };

    const handleSubmit = async event => {
        setLoading(true);
        event.preventDefault();
        try {
            await AuthAPI.authenticate(user, setUserLocal);
            setIsAuthenticated(true);
            history.replace('/');
        } catch (error) {
            setError('Identifiants invalides');
        }
        setLoading(false);
    };


    return (
        <div className="content-body">
            <div className="container">
                <div className="columns is-mobile is-centered">

                    <div className="column is-half-desktop is-three-quarters-tablet is-four-fifths-mobile">
                        <h1 className="subtitle is-1 has-text-centered">Connexion </h1>

                        <form onSubmit={handleSubmit}>
                            <Field label="Email "
                                   name="username"
                                   type="username"
                                   required={true}
                                   icon="envelope"
                                   value={user.username}
                                   error={error}
                                   onChange={handleChange}
                            />

                            <Field label="Mot de passe : "
                                   name="password"
                                   type="password"
                                   required={true}
                                   icon="lock"
                                   value={user.password}
                                   onChange={handleChange}
                            />

                            <div className=" has-text-centered">
                                <button className="button is-success" type="submit">
                                {loading && <div><i class="fas fa-spinner fa-spin"/></div> ||
                                        "Connexion"
                                }
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default LoginPage;
