import React from 'react';

const Legals = () => {
	return (
		<div className="content-body">
			<div className="container">
				<h1 className="title is-size-2-desktop">Conditions générales d'utilisation</h1>
				<p>
					<em>En vigueur au 01/02/2020</em>
				</p>
				<p>&nbsp;</p>
				<p>
					Les présentes conditions générales d'utilisation
					(dites &laquo; CGU &raquo;) ont pour objet l'encadrement juridique des
					modalités de mise à disposition du site et des services
					par et de définir les conditions d'accès et
					d'utilisation des services par &laquo; l'Utilisateur &raquo;.
				</p>
				<p>
					Les présentes CGU sont accessibles sur le site à la
					rubrique &laquo;CGU&raquo;.
				</p>
				<p>
					Toute inscription ou utilisation du site implique l'acceptation sans
					aucune réserve ni restriction des présentes CGU par
					l'utilisateur. Lors de l'inscription sur le site via le
					Formulaire d'inscription, chaque utilisateur accepte
					expressément les présentes CGU en cochant la case
					précédant le texte suivant : &laquo;{' '}
					<em>Je reconnais avoir lu et compris les CGU et je les accepte</em>{' '}
					&raquo;.
				</p>
				<p>
					En cas de non-acceptation des CGU stipulées dans le
					présent contrat, l'Utilisateur se doit de renoncer à
					l'accès des services proposés par le site.
				</p>
				<p>
					<em>www.cotationsfl.fr</em> se réserve le droit de modifier
					unilatéralement et à tout moment le contenu des
					présentes CGU.
				</p>
                <br/>
				<h1 className="title is-size-2-desktop">Article 1 : Les mentions légales</h1>
				<p>
					L'édition du site www.cotationsfl.fr est assurée par
					l'Association URPS Sage-Femme Grand Est, située au 25-29
					rue de Saurupt 54000 Nancy
				</p>
				<p>Adresse e-mail : <a href="mailto:urps.sagefemme.grandest@gmail.com">urps.sagefemme.grandest@gmail.com</a>.</p>
				<p>Le Directeur de la publication est : Caroline Combot</p>
				<p>
					L'hébergeur du site www.cotationsfl.fr est la
					société 02Switch, dont le siège social est
					situé au 224 boulevard Gustave Flaubert 63000 Clermont-Ferrand,
					avec le numéro de téléphone : 04 44 44 60 40.
				</p>
                <br/>
				<h1 className="title is-size-2-desktop">Article 2&nbsp;: Accès au site</h1>
				<p>
					Le site <em>www.cotationsfl.fr</em> permet à l'Utilisateur un
					accès gratuit aux services suivants :
				</p>
				<p>Le site internet propose les services suivants :</p>
				<p>Information sur les cotations des actes pour les sages-femmes</p>
				<p>
					Le site est accessible gratuitement en tout lieu à tout
					Utilisateur ayant un accès à Internet. Tous les frais
					supportés par l'Utilisateur pour accéder au service
					(matériel informatique, logiciels, connexion Internet, etc.)
					sont à sa charge.
				</p>
				<p>
					L'Utilisateur non membre n'a pas accès aux services
					réservés. Pour cela, il doit s'inscrire en
					remplissant le formulaire. En acceptant de s'inscrire aux
					services réservés, l'Utilisateur membre
					s'engage à fournir des informations sincères et
					exactes concernant son état civil et ses coordonnées,
					notamment son adresse email.
				</p>
				<p>
					Pour accéder aux services, l'Utilisateur doit ensuite
					s'identifier à l'aide de son identifiant et de son mot de passe
					qui lui seront communiqués après son inscription.
				</p>
				<p>
					Tout Utilisateur membre régulièrement inscrit pourra
					également solliciter sa désinscription en se rendant
					à la page dédiée sur son espace personnel.
					Celle-ci sera effective dans un délai raisonnable.
				</p>
				<p>
					Tout événement d&ucirc; à un cas de force majeure
					ayant pour conséquence un dysfonctionnement du site ou serveur
					et sous réserve de toute interruption ou modification en cas de
					maintenance, n'engage pas la responsabilité de{' '}
					<em>www.cotationsfl.fr. </em>Dans ces cas, l'Utilisateur accepte
					ainsi ne pas tenir rigueur à l'éditeur de toute
					interruption ou suspension de service, même sans préavis.
				</p>
				<p>
					L'Utilisateur a la possibilité de contacter le site par
					messagerie électronique à l'adresse email de
					l'éditeur communiqué à l'Article 1.
				</p>
                <br/>
				<h1 className="title is-size-2-desktop">Article 3 : Collecte des données</h1>
				<p>
					En vertu de la loi Informatique et Libertés, en date du 6
					janvier 1978, l'Utilisateur dispose d'un droit d'accès, de
					rectification, de suppression et d'opposition de ses données
					personnelles. L'Utilisateur exerce ce droit :
				</p>
				<ul>
					<li>
						par mail à l'adresse mail&nbsp;
						<em><a href="mailto:urps.sagefemme.grandest@gmail.com">urps.sagefemme.grandest@gmail.com</a></em>
					</li>
				</ul>
                <br/>
				<h1 className="title is-size-2-desktop">Article 4&nbsp;: Propriété intellectuelle</h1>
				<p>
					L'Utilisateur doit solliciter l'autorisation préalable du site
					pour toute reproduction, publication, copie des différents
					contenus. Il s'engage à une utilisation des contenus du site
					dans un cadre strictement privé, toute utilisation à des
					fins commerciales et publicitaires est strictement interdite.
				</p>
				<p>
					Toute représentation totale ou partielle de ce site par quelque
					procédé que ce soit, sans l'autorisation expresse
					de l'exploitant du site Internet constituerait une
					contrefa&ccedil;on sanctionnée par l'Article L 335-2 et
					suivants du Code de la propriété intellectuelle.
				</p>
				<p>
					Il est rappelé conformément à l'Article
					L122-5 du Code de propriété intellectuelle que
					l'Utilisateur qui reproduit, copie ou publie le contenu
					protégé doit citer l'auteur et sa source.
				</p>
				<p>&nbsp;</p>
                <br/>
				<h1 className="title is-size-2-desktop">Article 5&nbsp;: Responsabilité</h1>
				<p>
					Les sources des informations diffusées sur le site
					www.cotationsfl.fr sont réputées fiables mais le site ne
					garantit pas qu'il soit exempt de défauts,
					d'erreurs ou d'omissions.
				</p>
				<p>
					Les informations communiquées sont présentées
					à titre indicatif et général sans valeur
					contractuelle. Malgré des mises à jour
					régulières, le site <em>www.cotationsfl.fr&nbsp;</em>ne
					peut être tenu responsable de la modification des dispositions
					administratives et juridiques survenant après la publication.
					De même, le site ne peut être tenue responsable de
					l'utilisation et de l'interprétation de
					l'information contenue dans ce site.
				</p>
				<p>
					L'Utilisateur s'assure de garder son mot de passe secret. Toute
					divulgation du mot de passe, quelle que soit sa forme, est interdite.
					Il assume les risques liés à l'utilisation de son
					identifiant et mot de passe. Le site décline toute
					responsabilité.
				</p>
				<p>
					Le site <em>www.cotationsfl.fr&nbsp;</em>ne peut être tenu pour
					responsable d'éventuels virus qui pourraient infecter
					l'ordinateur ou tout matériel informatique de
					l'Internaute, suite à une utilisation, à
					l'accès, ou au téléchargement provenant de
					ce site.
				</p>
				<p>
					La responsabilité du site ne peut être engagée en
					cas de force majeure ou du fait imprévisible et insurmontable
					d'un tiers.
				</p>
                <br/>
				<h1 className="title is-size-2-desktop">Article 6&nbsp;: Liens hypertextes</h1>
				<p>
					Des liens hypertextes peuvent être présents sur le site.
					L'Utilisateur est informé qu'en cliquant sur ces
					liens, il sortira du site <em>www.cotationsfl.fr.</em> Ce dernier
					n'a pas de contr&ocirc;le sur les pages web sur lesquelles
					aboutissent ces liens et ne saurait, en aucun cas, être
					responsable de leur contenu.
				</p>
                <br/>
				<h1 className="title is-size-2-desktop">Article 7 : Cookies</h1>
				<p>
					L'Utilisateur est informé que lors de ses visites sur le
					site, un cookie peut s'installer automatiquement sur son
					logiciel de navigation.
				</p>
				<p>
					Les cookies sont de petits fichiers stockés temporairement sur
					le disque dur de l'ordinateur de l'Utilisateur par votre
					navigateur et qui sont nécessaires à l'utilisation
					du site <em>www.cotationsfl.fr</em>. Les cookies ne contiennent pas
					d'information personnelle et ne peuvent pas être
					utilisés pour identifier quelqu'un. Un cookie contient un
					identifiant unique, généré aléatoirement
					et donc anonyme. Certains cookies expirent à la fin de la
					visite de l'Utilisateur, d'autres restent.
				</p>
				<p>
					L'information contenue dans les cookies est utilisée pour
					améliorer le site <em>www.cotationsfl.fr</em>.
				</p>
				<p>En naviguant sur le site, L'Utilisateur les accepte.</p>
				<p>
					L'Utilisateur pourra désactiver ces cookies par
					l'intermédiaire des paramètres figurant au sein de
					son logiciel de navigation.
				</p>
                <br/>
				<h1 className="title is-size-2-desktop">
					Article 8&nbsp;: Droit applicable et juridiction compétente
				</h1>
				<p>
					La législation fran&ccedil;aise s'applique au présent
					contrat. En cas d'absence de résolution amiable d'un litige
					né entre les parties, les tribunaux fran&ccedil;ais seront
					seuls compétents pour en conna&icirc;tre.
				</p>
				<p>
					Pour toute question relative à l'application des
					présentes CGU, vous pouvez joindre l'éditeur aux
					coordonnées inscrites à l'Article 1.
				</p>
                <br/>
			</div>
		</div>
	);
};

export default Legals;
