import React from "react";
import './Footer.scss';
import {Link} from "react-router-dom";
import logo from "../../images/logoURPS.jpg";

const Footer = () => {
    return (
        <div className="hero-foot has-background-light">
            <div className="block-footer">
                <ul className="flex-footer">
                    <li>
                        <Link to="/legals" className="footer-link">
                            Conditions générales
                        </Link>
                    </li>
                    &nbsp;&nbsp;
                    <img src={logo} height="60" width="60" alt="logo URPS"/>
                    &nbsp;&nbsp;
                    <li>
                        © 2019 URPS Grand-Est
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default Footer;
