import React, { useState } from 'react';
import ChoiceList from "./ChoiceList";
import Transport from "./Transport";
import Result from "./Result";
import './style.scss'

const Index = () => {
    const [step, setStep] = useState(1);

    const [selectedActs, setSelectedActs] = useState([]);

    const [mileage, setMileage] = useState({
        typeKey: 4,
        distance: '',
    });

    const [increase, setIncrease] = useState([]);

    const resetConsultation = () => {
        setStep(1);
        setSelectedActs([]);
        setMileage({
            typeKey: 4,
            distance: '',
        })
    };

    switch (step) {
        case 1:
            return (
                <ChoiceList
                    step={step}
                    setStep={setStep}
                    selectedActs={selectedActs}
                    setSelectedActs={setSelectedActs}
                    setIncrease={setIncrease}
                />
            );
        case 2:
            return (
                <Transport
                    step={step}
                    setStep={setStep}
                    selectedActs={selectedActs}
                    mileage={mileage}
                    setMileage={setMileage}
                    increase={increase}
                    setIncrease={setIncrease}
                    reset={resetConsultation}
                />
            );
        case 3:
            return (
                <Result
                    step={step}
                    setStep={setStep}
                    selectedActs={selectedActs}
                    mileage={mileage}
                    reset={resetConsultation}
                    increase={increase}
                />
            );
        default:
            return (
                <ChoiceList
                    step={step}
                    setStep={setStep}
                    selectedActs={selectedActs}
                    setSelectedActs={setSelectedActs}
                    setIncrease={setIncrease}
                />
            )

    }
};

export default Index;
