import { useState } from 'react';

export function useToggle(initialValue = null) {
    const [value, setValue] = useState(initialValue);

    return[
        value,
        () => setValue(!value)
    ]
}
