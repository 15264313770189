import Majoration from "../majoration";


/**
 * build array of selected acts with prices according to User
 * @param selectedActs
 * @param aUser
 * @returns {*}
 */
function getSelectedActsPrices(selectedActs, aUser) {
    return selectedActs.map((act) => {
        if (!aUser.metropole) {
            if (act.domtomPrice > 0) {
                return act.domtomPrice;
            }
        }
        return act.metropolePrice;
    });
}

/**
 * build an array of selected acts of prices with ratio applied
 * @param selectedActs
 * @param user
 * @returns {*[]}
 */
function getActsRatio(selectedActs, user) {

    const selectActsRawPrices = getSelectedActsPrices(selectedActs, user);
    if (selectActsRawPrices.length < 2) return selectActsRawPrices;
    if (selectedActs[0].rule.id === 1) return selectActsRawPrices;

    const selectedCodes = selectedActs.map(act => act.code)

    //we got 2 or 3 acts : check no one is a ratio act
    const foundRatioAct = Majoration.ACTS_MAJORATION.some(actMajoration => selectedCodes.includes(actMajoration))
    if (foundRatioAct && selectedActs.length == 2) return selectActsRawPrices;

    if (foundRatioAct && selectedActs.length == 3) {
        const ratioPosition = selectedCodes.indexOf("MSF");
        switch (ratioPosition) {
            // ratio is in the middle
            case 1: {
                let newActsRaw = [selectActsRawPrices[0], selectActsRawPrices[2]]
                let res = apply50Ratio(selectedActs[0].rule.id, newActsRaw);
                return [res[0]].concat([selectActsRawPrices[1]]).concat([res[1]])
            }
            case 2: {
                let res = apply50Ratio(selectedActs[0].rule.id, selectActsRawPrices.slice(0, 2));
                return res.concat([selectActsRawPrices[2]])
            }
            default:
                return selectActsRawPrices;
        }
    }


    return apply50Ratio(selectedActs[0].rule.id, selectActsRawPrices)
}

function apply50Ratio(ruleId, selectActsRawPrices) {
    let actsCalculatedPrices = [...selectActsRawPrices];

    switch (ruleId) {
        case 1:
        case 2:
        case 4:
            const minPrice = Math.min(...selectActsRawPrices);
            actsCalculatedPrices[actsCalculatedPrices.indexOf(minPrice)] = 0.5 * minPrice
    }
    return actsCalculatedPrices;
}


export default {
    getSelectedActsPrices,
    getActsRatio,
}
