const TRANSPORTS_OPTIONS = [
    {code: "IK", type: "Plaine", metropolePrice: 0.45, domtomPrice: 0.59},
    {code: "IKM", type: "Montagne", metropolePrice: 0.73, domtomPrice: 0.80},
    {code: "IK", type: "Ski ou à pied", metropolePrice: 3.95, domtomPrice: 4.35},
    {code: "IFD", type: "Indemnité forfaitaire de déplacement", metropolePrice: 4, domtomPrice: 4.40}
];

const TRANSPORTS = TRANSPORTS_OPTIONS.concat([
    {code: "", type: "Consultation au cabinet", metropolePrice: 0, domtomPrice: 0},  // NO TRANSPORT
]);


const CCAM_RATIO = [
    {code: "P", type: "Urgent entre 20h et minuit", price: 35},
    {code: "S", type: "Urgent entre minuit et 8h", price: 40},
    {code: "F", type: "Urgent un dimanche ou jour férié", price: 19.06},
    {code: "M", type: "Soin d'urgence fait au cabinet", price: 26.88}
];

const NGAP_RATIO = [
    {code: "F", type: "Dimanche et jour férié", price: 21},
    {code: "N", type: "Nuit de 20h à minuit ou 6h à 8h", price: 35},
    {code: "MM", type: "Nuit de minuit à 6h", price: 40},
];

const ACTS_MAJORATION = [
    "MSF", // hors-grossesse
    "DSP", // suivi post natal
    "K", // accouchement
];

export default {
    TRANSPORTS,
    TRANSPORTS_OPTIONS,
    CCAM_RATIO,
    NGAP_RATIO,
    ACTS_MAJORATION,
}
