import React, {useState} from "react";
import Field from "../components/form/Field";
import axios from "axios";
import {toast} from "react-toastify";
import Select from "../components/form/Select";

const RegisterPage = ({history}) => {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        rpps: '',
        metropole: "true",
        password: '',
        passwordConfirm: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        rpps: '',
        metropole: '',
        password: '',
        passwordConfirm: '',
    });

    const handleChange = ({target}) => {
        const name = target.name;
        setUser({
            ...user,
            [name]: target.value
        });
    };

    const handleSubmit = async event => {
        setLoading(true);
        event.preventDefault();
        const apiErrors = {};
        if (user.password === user.passwordConfirm) {
            try {
                await axios.post(process.env.REACT_APP_API_ENTRYPOINT + "/users",
                    {...user, metropole: (user.metropole === "true"), rpps: parseInt(user.rpps)});
                console.log({...user, metropole: (user.metropole === "true"), rpps: parseInt(user.rpps)});
                setErrors({});
                toast.success("  Compte ajouté");
                history.push("/login");
            } catch ({response}) {
                const {violations} = response.data;
                if (violations) {
                    violations.map(({propertyPath, message}) => {
                        apiErrors[propertyPath] = message;
                    });
                    toast.error("  Inscription impossible");
                    setErrors(apiErrors);
                }
                if (user.passwordConfirm === "") {
                    setErrors({...apiErrors, passwordConfirm: "la confirmation est requise"});
                }
            }
        } else {
            setErrors({
                ...apiErrors,
                password: "Vos mot de passe sont différents",
                passwordConfirm: "Vos mot de passe sont différents"
            });
        }
        setLoading(false);
    };

    return (
        <>
            <div className="content-body">
                <div className="container">
                    <div className="columns is-mobile is-centered">

                        <div className="column is-half-desktop is-three-quarters-tablet is-four-fifths-mobile">
                            <h1 className="subtitle is-1 has-text-centered">Inscription </h1>

                            <form onSubmit={handleSubmit}>
                                <Field label="Prénom "
                                       name="firstName"
                                       type="firstName"
                                       required={true}
                                       icon="user"
                                       value={user.firstName}
                                       error={errors.firstName}
                                       onChange={handleChange}
                                />

                                <Field label="Nom "
                                       name="lastName"
                                       type="lastName"
                                       required={true}
                                       icon="user"
                                       value={user.lastName}
                                       error={errors.lastName}
                                       onChange={handleChange}
                                />

                                <Field label="Email "
                                       name="email"
                                       type="email"
                                       required={true}
                                       icon="envelope"
                                       value={user.email}
                                       error={errors.email}
                                       onChange={handleChange}
                                />

                                <Field label="Adresse de votre cabinet "
                                       name="address"
                                       type="address"
                                       placeholder="votre adresse ..."
                                       required={true}
                                       icon="map-marker-alt"
                                       value={user.address}
                                       error={errors.address}
                                       onChange={handleChange}
                                />

                                <Field label="Numéro RPPS "
                                       name="rpps"
                                       type="rpps"
                                       required={true}
                                       icon="id-card"
                                       value={user.rpps}
                                       error={errors.rpps}
                                       onChange={handleChange}
                                />

                                <Select label="Métropole ou DOM-TOM"
                                        name="metropole"
                                        icon="globe"
                                        value={user.metropole}
                                        error={errors.metropole}
                                        onChange={handleChange}
                                >
                                    <option value={true}>Métropole</option>
                                    <option value={false}>DOM-TOM</option>
                                </Select>


                                <Field label="Mot de passe : "
                                       name="password"
                                       type="password"
                                       required={true}
                                       icon="lock"
                                       value={user.password}
                                       error={errors.password}
                                       onChange={handleChange}
                                />

                                <Field label="Confirmation du mot de passe : "
                                       name="passwordConfirm"
                                       type="password"
                                       required={true}
                                       icon="lock"
                                       value={user.passwordConfirm}
                                       error={errors.passwordConfirm}
                                       onChange={handleChange}
                                />

                                <div className="has-text-centered">
                                    <button className="button is-success" type="submit">
                                        {loading && <div><i class="fas fa-spinner fa-spin"/></div> ||
                                        "S'inscrire"}
                                        </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
};

export default RegisterPage;
