import Majoration from "../majoration";

/**
 * handles acts type filter (CCAM / NGAP) according to the rule of 1s selected act
 * @param act
 * @param selectedActs
 * @returns {boolean}
 */
function handleRulesTypes(act, selectedActs) {
    if (selectedActs.length && selectedActs[0].rule) {
        const ruleId = selectedActs[0].rule.id;
        switch (ruleId) {
            // if CCAM selected => only show NGAP
            case 4:
                if (selectedActs[0].type === "CCAM") {
                    return act.type === "NGAP"
                }
                return true;
            // if NGAP selected => only show CCAM
            case 2:
                if (selectedActs[0].type === "NGAP") {
                    return act.type === "CCAM"
                }
                return true;
            // Règle 3 : on ne peut sélectionner que des actes NGAP
            case 3:
                if (selectedActs[0].type === "NGAP") {
                    return act.type === "NGAP"
                }
                return true;
            default:
                return true
        }
    }
    return true;
}

/**
 * handles acts exclusion filter (only show some acts) depending of rule of 1st selected act
 * @param act
 * @param selectedActs
 * @returns {boolean}
 */
function handleConsultationFilter(act, selectedActs) {
    // quand consultation permettre de cocher seulement frottis et échographie de grossesse T1, T2, T3 & majoration MSF
    const ACTS_CONSULTATION = ["C+MSF", "V+MSF", ];
    const ACTS_FOR_CONSULTATION = ["JQHD001", "JQQM010", "JQQM015", "JQQM018", "JQQM019", "JQQM016", "JQQM017", "MSF"];

    if (selectedActs.length && selectedActs[0].rule) {
        const ruleId = +selectedActs[0].rule.id;
        const codeSelected = selectedActs[0].code;

        // si on selectionne C ou V
        if (ACTS_CONSULTATION.includes(selectedActs[0].code)) {
            return ACTS_FOR_CONSULTATION.includes(act.code)
            // si on frottis
        } else if (selectedActs[0].code === "JQHD001") {
          return ACTS_FOR_CONSULTATION.includes(act.code) || act.rule.id === 1;
        }
        else if (!ACTS_FOR_CONSULTATION.includes(codeSelected)) {
            return !ACTS_CONSULTATION.includes(act.code);
        }
    }
    return true;
}

function removeMajorationAct(act, selectedActs) {

    if (selectedActs.length === 0) {
        return !Majoration.ACTS_MAJORATION.includes(act.code);
    }
    return true;
}

function showMajorationAct(act, selectedActs) {

    if (selectedActs.length) {
        const codeSelected = selectedActs[0].code;

        if (act.code === "MSF") {
            return ["C", "V"].includes(codeSelected)
        } else if (act.code === "K") {
            return codeSelected.startsWith("JQGD")
        } else if (act.code === "DSP") {
            return codeSelected + selectedActs[0].factor === "SF16.5"
        }
    }
    return true;
}


function handleAccouchementFilter(act, selectedActs) {

    if (selectedActs.length && selectedActs[0].rule) {
        const codeSelected = selectedActs[0].code;

        // acte JQQP099 doit être incompatible avec tous les JQGD
        if (codeSelected.startsWith("JQQP099")) {
            return !act.code.startsWith("JQGD");
        } else if (codeSelected.startsWith("JQGD")) {
            return !act.code.startsWith("JQQP099");
        }
    }
    return true;
}

function handleGrossesseFilter(act, selectedActs) {

    if (selectedActs.length && selectedActs[0].rule) {
        const codeSelected = selectedActs[0].code;
        const ACTS_FOR_ACCUPUNCTURE = ["SP", "SF15", "SF11.6", "SF12", "SF6", "SF9"];

        const selectedCodeFactor = codeSelected + (selectedActs[0].factor == null ? '' : selectedActs[0].factor);

        // acte QZRB001 doit être compatible avec slmt ACTS_FOR_ACCUPUNCTURE
        if (codeSelected.startsWith("QZRB001")) {
            return ACTS_FOR_ACCUPUNCTURE.includes(act.code + (act.factor == null ? '' : act.factor));
            // si pas ACTS_FOR_ACCUPUNCTURE on enleve QZRB001
        } else if (!ACTS_FOR_ACCUPUNCTURE.includes(selectedCodeFactor)) {
            return !act.code.startsWith("QZRB001");
        }
    }
    return true;
}

function handleMaxActRule(selectedActs) {
    if (selectedActs.length) {
        const codeSelected = selectedActs[0].code;

        if (["C", "V"].includes(codeSelected)) {
            return (selectedActs.length <= 2)
        }
    }
    return (selectedActs.length <= 1)
}


export default {
    handleRulesTypes,
    showMajorationAct,
    removeMajorationAct,
    handleConsultationFilter,
    handleAccouchementFilter,
    handleGrossesseFilter,
    handleMaxActRule,
}
