import React, { useState } from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Result from "./Result";
import './Periods.css';
import { useToggle } from "../../services/hooks";

registerLocale("fr", fr);

const Periods = () => {

    const [startDate, setStartDate] = useState(new Date());
    const [withPeriods, togglePeriods] = useToggle(false);

    const handleSelectChange = () => {
        togglePeriods(withPeriods)
    };

    return (
        <div className="content-body">

            <div className="container has-text-centered">
                <h1 className="title is-size-2-desktop">Choisissez une date</h1>
                <div className="title--block">
                    <h2 className="subtitle is-4">Calcul depuis &nbsp;&nbsp;</h2>
                    <div className="select is-success">
                        <select onChange={handleSelectChange}>
                            <option>DDG</option>
                            <option>DDR</option>
                        </select>
                    </div>
                </div>
                <DatePicker
                    locale="fr"
                    dateFormat="d MMMM yyyy"
                    todayButton="Aujourd'hui"
                    inline
                    selected={startDate}
                    onChange={(date) => {setStartDate(date)}}
                />
                <br/>
                <br/>
                <h3 className="title is-5">Résultat (terme prévu) :</h3>
                <Result
                    date={startDate}
                    withPeriods={withPeriods}
                />
            </div>
        </div>
    );
};

export default Periods;
