import React, {useState} from 'react';
import { useToggle } from '../../services/hooks';
import {FaEyeSlash, FaEye} from 'react-icons/fa';

const Field = ({name, label, value, onChange, placeholder = '', type = 'text', error = '', icon = false, readOnly = false, required = false}) => {

    const [fieldType, setType] = useState(type)

    return (
        <>
        <label className="label" htmlFor={name}>{label}</label>
        <div className="field has-addons">
            <div className="control has-icons-left is-expanded">
                <input
                    required={required}
                    readOnly={readOnly}
                    name={name}
                    value={value}
                    placeholder={placeholder || label}
                    type={fieldType}
                    onChange={onChange}
                    className={"input" + (error && " is-danger")}
                />
                {icon && <span className="icon is-small is-left"><i className={"fas fa-" + icon}/></span>}
            </div>
            {type == 'password' && <RevealPassword setType={setType} />}
            {error && <p className="help is-danger">{error}</p>}
        </div>
        </>
    );
};  

const RevealPassword = ({setType}) => {
    const [showPassword, toggleShowPassword] = useToggle(false)

    const toggleReveal = () => {
        toggleShowPassword(showPassword)
        setType(showPassword ? 'password' : 'text')
    }
    return(
        <div class="control" onClick={toggleReveal}>
            <a className="button is-static">
                <div>
                    {showPassword && <FaEye /> || <FaEyeSlash />}
                </div>
            </a>
        </div>
    )
}

export default Field;
