import React from 'react';

const Pagination = ({currentPage, itemsPerPage, length, onPageChange}) => {

    const pagesCount = Math.ceil(length / itemsPerPage);
    const pages = [];
    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }

    const handlePageChange = nextPage => {
        onPageChange(nextPage);
        window.scrollTo({ top: 0});
    }

    return (
        <nav className="pagination is-centered is-rounded" role="navigation" aria-label="pagination" style={{marginTop: 10}}>
            {currentPage !== 1 &&
            <button className='pagination-previous'
               onClick={() => handlePageChange(currentPage - 1)}>
                &laquo;
            </button>
            }
            {currentPage !== pagesCount &&
            <button className='pagination-next'
               onClick={() => handlePageChange(currentPage + 1)}>
                &raquo;
            </button>
            }
            <ul className="pagination-list">
                {pages.map(page =>
                    <li key={page}><button
                        key={page}
                        className={'pagination-link ' + (currentPage === page && 'is-current')}
                        onClick={() => handlePageChange(page)}>
                        {page}
                    </button></li>
                )}
            </ul>
        </nav>
    );
};

Pagination.getData = (items, currentPage, itemsPerPage) => {
    const start = (currentPage - 1) * itemsPerPage;
    return items.slice(start, start + itemsPerPage);
};

export default Pagination;
