import React, {useContext} from "react";
import {Link, NavLink} from "react-router-dom";
import AuthContext from '../contexts/AuthContext';
import AuthAPI from '../services/authAPI';

const NavBar = ({history}) => {

    /* eslint-disable */
    const {isAuthenticated, setIsAuthenticated, setUser} = useContext(AuthContext);
    const logout = () => {
        AuthAPI.logout();
        setIsAuthenticated(false);
        setUser({});
        history.push('/login');
    };

    return (
        <div className="hero-head has-background-primary">
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/#">
                       <span className="icon">
                          <i className="fas fa-home"/>
                        </span>
                    </a>
                    <a role="button" className="navbar-burger" data-target="navMenu" aria-label="menu"
                       aria-expanded="false">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navMenu" className="navbar-menu">
                    <div className="navbar-start">
                        <NavLink to="/periods" className="navbar-item" activeClassName="">
                            Calcul du terme
                        </NavLink>
                        <Link to="/faqs" className="navbar-item">
                            F.A.Q.
                        </Link>
                        <Link to="/actes" className="navbar-item">
                            Liste des Actes
                        </Link>
                        <Link to="/consultation" className="navbar-item">
                            Cotation
                        </Link>
                    </div>

                    <div className="navbar-end">
                        {(!isAuthenticated &&
                            <>
                                <div className="navbar-item">
                                    <Link to="/register" className="navbar-item button is-light">
                                        Inscription
                                    </Link>
                                </div>
                                <div className="navbar-item">
                                    <Link to="/login" className="navbar-item button is-light">
                                        Connexion
                                    </Link>
                                </div>
                            </>)
                        ||
                        <>
                            <Link to="/profil" className="navbar-item">
                                <i className="fas fa-user-alt"/>&nbsp;Mon Compte
                            </Link>
                            <div className="navbar-item">
                                <a onClick={logout}
                                   className="button is-warning is-outlined is-rounded">
                                    Déconnexion
                                </a>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </nav>
        </div>
    )

};

export default NavBar;
